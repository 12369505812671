<template>
  <v-dialog
    v-model="dialogVendaInfo"
    @click:outside="fecharVendaInfo"
    @keydown.esc="fecharVendaInfo"
    width="70vw"
    scrollable
  >
    <v-card tile>
      <!-- HEADER DIALOG -->
      <v-card-title class="px-6 tocs_gray_1">
        <span>
          {{ $tc("global.venda") }}
        </span>
        <span class="mx-2">{{ venda.numero + " - " }}</span>
        <span>
          {{
            venda.condicao_pagamento === 2
              ? $tc("global.aPrazo")
              : $tc("global.aVista")
          }}
        </span>

        <v-spacer></v-spacer>

        <span class="pr-4">
          {{ venda.data | dateFormat("dd/MM/yyyy") }}
        </span>

        <v-btn icon @click="fecharVendaInfo">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- CORPO DIALOG -->
      <v-card-text class="pa-6">
        <!-- DADOS DO CLIENTE COM DEPOSITO VALOR E DESCONTO -->
        <v-card
          width="100%"
          outlined
          class="d-flex justify-space-between align-center pa-4"
        >
          <div>
            <span class="text-h6 font-weight-bold">
              {{ $tc("global.cliente") }}:
            </span>
            <span class="subtitle-1 ml-2">{{ venda.cliente_nome }}</span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.deposito") }}:
              </span>
              {{ venda.deposito_nome }}
            </span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.valor") }}:
              </span>
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ venda.moeda_sigla }} {{ venda.valor | guarani }}
              </span>
              <span v-else>
                {{ venda.moeda_sigla }} {{ venda.valor | currency }}
              </span>
            </span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.desconto") }}:
              </span>
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ venda.moeda_sigla }} {{ venda.desconto | guarani }}
              </span>
              <span v-else>
                {{ venda.moeda_sigla }} {{ venda.desconto | currency }}
              </span>
            </span>
          </div>
        </v-card>

        <div class="mt-4"></div>

        <!-- QUANDO FOR PARCELADO -->
        <v-divider v-if="venda.condicao_pagamento == 2"></v-divider>
        <v-data-table
          v-if="venda.condicao_pagamento == 2"
          :headers="headers_parcelas"
          :items="venda_parcelas"
          :items-per-page="-1"
          :sort-desc="true"
          class=""
          hide-default-footer
        >
          <template v-slot:item.numero_parcela="{ item }">
            <span v-if="item.entrega_inicial"> Entrega inicial </span>
            <span v-else>
              {{ item.numero_parcela }}
            </span>
          </template>

          <template v-slot:item.total_parcelas="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.total_parcelas }}
            </span>
          </template>

          <template v-slot:item.vencimento="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.vencimento | dateFormat("dd/MM/yyyy") }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ venda.moeda_sigla }} {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ venda.moeda_sigla }} {{ item.valor | currency }}
            </span>
          </template>
        </v-data-table>

        <v-divider
          class="mb-4"
          v-if="venda.condicao_pagamento == 2"
        ></v-divider>

        <span class="text-subtitle-1 font-weight-bold">Produtos</span>

        <!-- VENDA ITENS-->
        <v-divider></v-divider>

        <!-- TABELA DE PRODUTOS -->
        <v-data-table
          :headers="venda_items_headers"
          :items="venda_items"
          :items-per-page="-1"
          disable-sort
          fixed-header
          show-expand
          :single-expand="true"
          class="data-tables row-pointer"
          :item-class="row_classes"
          hide-default-footer
        >
          <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          >
            <v-btn
              v-if="item.kit"
              icon
              small
              @click.stop="expand(isExpanded ? false : true)"
            >
              <v-icon>
                {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="12" class="px-0">
              <v-data-table
                :headers="headers_produtos_kit"
                :items="item.produtos_kit"
                :loading="loading"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
                :item-class="row_classes_kit"
                class="data-tables mb-8 mt-2 mx-6"
              >
                <!-- SLOTS PRODUTOS KIT -->
                <template v-slot:item.qtde="{ item }">
                  <template>
                    {{ item.qtde }}
                  </template>
                </template>

                <template v-slot:item.preco="{ item }">
                  {{ item.moeda_sigla }}
                  <div>
                    <span v-if="venda.moeda_sigla === 'G$'">
                      {{ item.preco | guarani }}
                    </span>
                    <span v-else>{{ item.preco | currency }} </span>
                  </div>
                </template>

                <template v-slot:item.total="{ item }">
                  <template>
                    <span v-if="venda.moeda_sigla === 'G$'">
                      {{ (item.qtde * item.preco) | guarani }}
                    </span>
                    <span v-else
                      >{{ (item.qtde * item.preco) | currency }}
                    </span>
                  </template>
                </template>
                <!-- FIM DOS SLOTS PRODUTOS KIT -->
              </v-data-table>
            </td>
          </template>

          <!-- SLOTS PRODUTOS -->
          <template v-slot:item.img="{ item }">
            <div class="my-1">
              <v-avatar v-if="item.img">
                <v-img max-width="65px" :src="item.img"></v-img>
              </v-avatar>
              <v-avatar v-else>
                <v-img
                  max-width="65px"
                  src="@/assets/product-placeholder.jpg"
                ></v-img>
              </v-avatar>
            </div>
          </template>

          <template v-slot:item.subtotal="{ item }">
            <template>
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ (item.qtde * item.preco) | guarani }}
              </span>
              <span v-else>{{ (item.qtde * item.preco) | currency }} </span>
            </template>
          </template>

          <template v-slot:item.qtde="{ item }">
            <template>
              {{ item.qtde }}
            </template>
          </template>

          <template v-slot:item.preco="{ item }">
            <div>
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ item.preco | guarani }}
              </span>
              <span v-else>{{ item.preco | currency }} </span>
            </div>
          </template>
        </v-data-table>
        <!-- FIM DOS SLOTS DOS PRODUTOS -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { fetchVendasItems } from "@/api/vendas/vendas_items.js";
export default {
  name: "DialogVendaInfo",

  props: {
    dialogVendaInfo: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
    },
    venda_parcelas: {
      type: Array,
    },
  },

  components: {},

  data() {
    return {
      loading: false,
      totalRestante: null,
      fatura_id: null,
      variaveis: [],
      venda_items: [],
      selectedMoeda: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      usuario: (state) => state.usuario,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },

    venda_items_headers() {
      let headers = [
        {
          text: "",
          divider: true,
          width: "70px",
          value: "img",
        },
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "descricao",
        },
        {
          text: "Qtde",
          divider: true,
          width: "150px",
          value: "qtde",
          align: "center",
        },
        {
          text: this.$tc("global.preco"),
          divider: true,
          width: "150px",
          value: "preco",
          align: "end",
        },

        {
          text: "SubTotal",
          divider: true,
          width: "150px",
          value: "subtotal",
          align: "end",
        },
      ];

      return headers;
    },
    headers_parcelas() {
      return [
        {
          text: this.$tc("global.numero") + " " + this.$tc("global.parcela"),
          value: "numero_parcela",
        },

        {
          text: this.$tc("global.total") + " " + this.$tc("global.parcela", 2),
          value: "total_parcelas",
        },
        {
          text: this.$tc("global.vencimento"),
          value: "vencimento",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.parcela"),
          value: "valor",
        },
      ];
    },
    headers_produtos_kit() {
      let headers = [
        {
          text: this.$tc("global.id"),
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.disponivel"),
          value: "estoque_disponivel",
        },
        {
          text: this.$tc("global.preco"),
          value: "preco",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
      ];

      if (!this.desabilitarEdicao) {
        let actions = {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        };

        headers.push(actions);
      }

      return headers;
    },
  },

  methods: {
    fecharVendaInfo() {
      this.$emit("update:dialogVendaInfo", false);
      this.$emit("fetch-vendas-caixa");
    },

    row_classes(linha) {
      if (
        this.venda_items.filter(
          (produto) => produto.produto_id === linha.produto_id
        ).length > 1
      ) {
        return "tocs_red--text";
      }
    },

    row_classes_kit(linha) {
      if (linha.qtde > linha.estoque_disponivel && !linha.controle_estoque) {
        return "tocs_red--text";
      }
    },

    async getVendaItems() {
      const response = await fetchVendasItems(
        this.venda && this.venda.id ? this.venda.id : this.venda_id
      );
      let items = [];
      response.forEach((produto) => {
        if (produto.tipo == "ITEM") {
          produto.produtos_kit = [];
          items.push(produto);
        }
      });
      response.forEach((produto) => {
        if (produto.tipo == "SUB-ITEM") {
          let index = items.findIndex((produto_array) => {
            return produto_array.id === produto.produto_pai_id;
          });
          produto.index_venda_item = index;
          items[index].produtos_kit.push(produto);
        }
      });

      items.forEach((produto) => {
        if (produto.produtos_kit.length) {
          produto.kit = true;
        }
      });

      this.venda_items = items;
    },
  },

  async mounted() {
    this.selectedMoeda = this.moedas.find((moeda) => {
      return moeda.id_moeda === this.venda.moeda_id;
    });
    await this.getVendaItems();
  },
};
</script>

<style></style>
